<template>
  <div class="buy_fiat">
    <div class="buy_box" v-for="(item, index) in list" :key="index">
      <div class="b_title2">{{ item.name }}</div>
      <div class="b_title">{{ item.price }} USDT/Hicoin</div>
      <div class="b_btn">
        <el-button class="btn_ok buy_btn" @click="buy_btn_fun(item)">
          {{ $t("main.text50") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrencyAgentList } from "../../../api/api";

export default {
  data() {
    return {
      amount: "",
      act_type: "SGD",
      list: [],
      userFeeConfig:{},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    buy_btn_fun(item) {
      this.$router.push({
        path: "/buy_jmhb_input",
        query: {
          agentUserid: item.agentUserid,
          price: item.price,
          user_rate: this.userFeeConfig.rate,
          user_fee: this.userFeeConfig.fee,
        },
      });
    },
    getList() {
      getCurrencyAgentList({
        type: 1,
      }).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.list;
          this.userFeeConfig = res.data.data.userFeeConfig;
        }
      });
    },
  },
};
</script>

<style scoped>
.buy_box {
  border-radius: 0.375rem;
  background: #fff;
  box-sizing: border-box;
  padding: 1.25rem;
  margin-bottom: 1.25rem;
}
.b_title {
  color: #000;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.b_title2 {
  color: #666;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}
.b_inp .el-input {
  height: 2.5rem;
}
.b_inp {
  margin-bottom: 1rem;
}
.b_inp2 {
  display: flex;
  height: 2.5rem;
  line-height: 2.5rem;
}
.b_inp2_1 {
  width: 5rem;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  background: #2982d8;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
}
.b_inp2_2 {
  flex: 1;
  background: #d6d6d6;
  text-indent: 1rem;
  border-radius: 0 0.25rem 0.25rem 0;
}
.act_type_list > div {
  line-height: 1.7rem;
}
.buy_btn {
  /* margin-top: 2.5rem; */
  height: 2.5rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
}
.b_btn >>> .el-button {
  width: 100%;
}
</style>
